import {
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  CircularProgress,
  TableContainer,
  Paper,
  FormControl,
  InputLabel,
  Link,
  Divider,
  Grid,
  Box
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { PrayTimes } from 'utils/PrayTimes'
import { Method, methods, months } from 'utils/constants'
import GoogleMaps, { type PlaceDetails, type Place, type PlaceType } from 'components/GoogleMaps'
import styles from 'app/index.module.scss'
import { daysInMonth } from 'utils/daysInMonth'
import { localeUses24HourTime } from 'utils/localeUses24HourTime'

interface Times {
  imsak: number
  fajr: number
  sunrise: number
  dhuhr: number
  asr: number
  sunset: number
  maghrib: number
  isha: number
}

interface TimeDetails {
  times: Times
  isToday: boolean
  dateString: string
}

const placeKey = 'last-used-place'
const detailsKey = 'last-used-details'
const methodKey = 'last-used-method'

function retrieveStored<T> (key: string): T | null {
  try {
    const stored = JSON.parse(localStorage.getItem(key) ?? 'null') as T
    if (stored === null) {
      return null
    }

    return stored
  } catch {
    return null
  }
}

const Index: React.FC = () => {
  const [fakeLoading, setFakeLoading] = useState(false)
  const [method, setMethod] = useState<Method>(Method.JAFARI)
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [elevation] = useState(0)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth())
  const [place, setPlace] = useState<PlaceType | null>()
  const [realLoading, setRealLoading] = useState(false)
  const [address, setAddress] = useState('')

  const getPrayTimes = useCallback((date: Date) => {
    return PrayTimes(method).getTimes(date, [latitude, longitude, elevation], 'auto', 'auto', localeUses24HourTime() ? '24h' : '12h') as Times
  }, [elevation, latitude, longitude, method])

  const todayTimeDetails: TimeDetails = useMemo(() => {
    const date = new Date()
    const today = {
      times: getPrayTimes(date),
      dateString: date.toLocaleDateString(),
      isToday: true
    }
    return today
  }, [getPrayTimes])

  const timeDetails: TimeDetails[] = useMemo(() => {
    const days = daysInMonth(year, month)
    const details: TimeDetails[] = []
    const now = new Date()
    for (let i = 1; i <= days; i++) {
      const date = new Date(year, month, i)
      details.push({
        times: getPrayTimes(date),
        dateString: date.toLocaleDateString(),
        isToday: year === now.getFullYear() && month === now.getMonth() && i === now.getDate()
      })
    }
    return details
  }, [getPrayTimes, month, year])

  const loading = useMemo(() => realLoading || fakeLoading, [fakeLoading, realLoading])

  const noLocation = useMemo(() => latitude === 0 && longitude === 0, [latitude, longitude])

  useEffect(() => {
    setFakeLoading(true)
    setTimeout(() => {
      setFakeLoading(false)
    }, 1500)
  }, [elevation, latitude, longitude, method, month, year])

  useEffect(() => {
    setRealLoading(true)
    const storedPlace = retrieveStored<PlaceType>(placeKey)
    const storedDetails = retrieveStored<PlaceDetails>(detailsKey)
    const storedMethod = retrieveStored<Method>(methodKey)
    setPlace(storedPlace ?? null)
    setLatitude(storedDetails?.geometry?.location?.lat ?? 0)
    setLongitude(storedDetails?.geometry?.location?.lng ?? 0)
    setAddress(storedPlace?.description ?? '')
    setMethod(storedMethod ?? Method.JAFARI)
    setRealLoading(false)
  }, [])

  function handleMethodChange (event: SelectChangeEvent<Method>) {
    setMethod(event.target.value as Method)
    localStorage.setItem(methodKey, JSON.stringify(event.target.value))
  }

  function handleMonthChange (event: SelectChangeEvent<string>) {
    setMonth(parseInt(event.target.value, 10))
  }

  function handleLocationChange (value: Place) {
    if (value.details) {
      setLatitude(value.details.geometry.location.lat)
      setLongitude(value.details.geometry.location.lng)
      setAddress(value.place.description)

      localStorage.setItem(placeKey, JSON.stringify(value.place))
      localStorage.setItem(detailsKey, JSON.stringify(value.details))
    }
  }

  function handleYearChange (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setYear(parseInt(event.target.value, 10))
  }

  return (
    <main>
      <header>
        <Typography variant='h4'>Prayer Timings</Typography>
        <Typography variant='caption'>
          Scientific, non-sectarian prayer times. All times are local. <Link href="http://praytimes.org/calculation" target={'_blank'} rel='noopener noreferrer'> Calculation method explanation</Link>. Consider a <Link href="https://square.link/u/tMINz3fp" target={'_blank'} rel="noreferrer">donation</Link>.
        </Typography>
      </header>
      <Divider />
      <article>
        <section className={styles.toolbarContainer} aria-label='toolbar'>
          <div className={styles.toolbar}>
            <FormControl>
              <InputLabel>Calculation method</InputLabel>
              <Select
                onChange={handleMethodChange}
                value={method}
                label={<>Calculation method</>}
              >
                {methods.map(({ id, name }) => <MenuItem key={id} value={id} selected={id === method}>{name}</MenuItem>)}
              </Select>
            </FormControl>
            <section className={styles.location}>
              <GoogleMaps onSelect={handleLocationChange} defaultValue={place} />
            </section>
          </div>
          <section className={styles.geometry}>
            <Typography>Address: {address}</Typography>
            <Typography>Coordinates: ({latitude.toFixed(3)}..., {longitude.toFixed(3)}...)</Typography>
          </section>
        </section>
        <Divider />
        {loading && (
          <section className={styles.spinner}>
            <CircularProgress />
          </section>
        )}
        {!loading && !noLocation && (
          <section className={styles.today}>
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} marginBottom={'0.25em'} marginTop={'0.5em'}>
              <Typography variant='h6'>Today: {todayTimeDetails.dateString}</Typography>
            </Box>
            <Box display={'flex'} gap={'1em'} flexWrap={'wrap'} alignItems={'center'}>
              <Grid>
                <Typography variant='caption'>Imsak</Typography>
                <Typography>{todayTimeDetails.times.imsak}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Fajr</Typography>
                <Typography>{todayTimeDetails.times.fajr}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Sunrise</Typography>
                <Typography>{todayTimeDetails.times.sunrise}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Dhuhr</Typography>
                <Typography>{todayTimeDetails.times.dhuhr}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Asr</Typography>
                <Typography>{todayTimeDetails.times.asr}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Sunset</Typography>
                <Typography>{todayTimeDetails.times.sunset}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Magrib</Typography>
                <Typography>{todayTimeDetails.times.maghrib}</Typography>
              </Grid>
              <Grid>
                <Typography variant='caption'>Isha</Typography>
                <Typography>{todayTimeDetails.times.isha}</Typography>
              </Grid>
            </Box>
          </section>
        )}
        <Divider />
        <section className={styles.toolbarContainer} aria-label='toolbar'>
          <section className={styles.toolbar}>
            <TextField label={'Year'} type='tel' onChange={handleYearChange} value={year} disabled={loading} />
            <FormControl disabled={loading}>
              <InputLabel>Month</InputLabel>
              <Select label="Month" value={month.toString()} onChange={handleMonthChange}>
                {months.map((m, index) => <MenuItem key={index} value={index} selected={index === month}>{m}</MenuItem>)}
              </Select>
            </FormControl>
          </section>
        </section>
        {loading && (
          <section className={styles.spinner}>
            <CircularProgress />
          </section>
        )}
        {!loading && !noLocation && (
          <section className={styles.table}>
            <TableContainer component={Paper}>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Imsak</TableCell>
                    <TableCell>Fajir</TableCell>
                    <TableCell>Sunrise</TableCell>
                    <TableCell>Dhuhr</TableCell>
                    <TableCell>Asr</TableCell>
                    <TableCell>Sunset</TableCell>
                    <TableCell>Magrib</TableCell>
                    <TableCell>Isha</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timeDetails.map(({ times, dateString, isToday }, index) => (
                    <TableRow key={index} selected={isToday}>
                      <TableCell>{dateString}</TableCell>
                      <TableCell>{times.imsak}</TableCell>
                      <TableCell>{times.fajr}</TableCell>
                      <TableCell>{times.sunrise}</TableCell>
                      <TableCell>{times.dhuhr}</TableCell>
                      <TableCell>{times.asr}</TableCell>
                      <TableCell>{times.sunset}</TableCell>
                      <TableCell>{times.maghrib}</TableCell>
                      <TableCell>{times.isha}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </section>
        )}
      </article>
    </main>
  )
}
export default Index
